/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function S5ProxyCategory() {
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="nav-item col-md-6">
        <a
          className="dropdown-item pb-2 d-flex"
          href=""
          onClick={() => {
            navigate(URL.List922S5Proxy);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/partner/922.png"
            alt="server"
            width={65}
            height={20}
          ></img>
          <span style={{alignSelf: 'center'}}>922 Proxy</span>
        </a>
        <a
          className="dropdown-item pt-0 d-flex"
          href=""
          onClick={() => {
            navigate(URL.ListPIAS5Proxy);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/partner/pia.png"
            alt="server"
            width={65}
            height={12}
          ></img>
          <span style={{alignSelf: 'center'}}>PIA Proxy</span>
        </a>
      </div>
    </div>
  );
}
