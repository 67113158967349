/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ProxyCategory() {
  const navigate = useNavigate();
  return (
    <div className="row" style={{ width: "500px" }}>
      <div className="nav-item col-md-6">
        <span className="ml-2">KHU VỰC VIỆT NAM</span>
        <a
          className="dropdown-item d-flex"
          href=""
          onClick={() => {
            navigate(URL.ListProxyVN);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/vietnam.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Việt Nam</span>
        </a>
      </div>
      <div className="nav-item col-md-6">
        <span className="ml-2">KHU VỰC CHÂU Á</span>
        <a
          className="dropdown-item d-flex pb-2"
          href=""
          onClick={() => {
            navigate(URL.ListProxySG);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/singapore.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Singapore (SG)</span>
        </a>
        <a
          className="dropdown-item d-flex pt-0"
          href=""
          onClick={() => {
            navigate(URL.ListProxyAU);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/australia.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Úc (AU)</span>
        </a>
      </div>
      <div className="nav-item col-md-6">
        <span className="ml-2">KHU VỰC CHÂU ÂU</span>
        <a
          className="dropdown-item d-flex pb-2"
          href=""
          onClick={() => {
            navigate(URL.ListProxyUK);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/united-kingdom.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Anh (UK)</span>
        </a>
        <a
          className="dropdown-item d-flex pt-0 pb-2"
          href=""
          onClick={() => {
            navigate(URL.ListProxyDE);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/germany.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Đức (DE)</span>
        </a>
        <a
          className="dropdown-item d-flex pt-0"
          href=""
          onClick={() => {
            navigate(URL.ListProxyFR);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/france.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Pháp (FR)</span>
        </a>
        <a
          className="dropdown-item d-flex pt-0"
          href=""
          onClick={() => {
            navigate(URL.ListProxyNL);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/netherlands.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Hà Lan (NL)</span>
        </a>
      </div>
      <div className="nav-item col-md-6">
        <span className="ml-2">KHU VỰC ÂU MỸ</span>
        <a
          className="dropdown-item d-flex pb-2"
          href=""
          onClick={() => {
            navigate(URL.ListProxyUS);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/united-states.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Mỹ (US)</span>
        </a>
        <a
          className="dropdown-item d-flex pt-0"
          style={{ background: "none" }}
          href=""
          onClick={() => {
            navigate(URL.ListProxyCA);
          }}
        >
          <img
            style={{
              alignSelf: "center",
              marginRight: "6px",
            }}
            src="assets/flags/canada.png"
            alt="server"
            width={35}
            height={35}
          ></img>
          <span style={{ alignSelf: "center" }}>Proxy Canada (CA)</span>
        </a>
      </div>
    </div>
  );
}
