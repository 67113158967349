/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Footer() {

  return (
    <>
      <section className="bg_0b257f padding_top_50 padding_bottom_50">
        <div className="container">
          <div className="row footer03_box">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="footer03_box_inner footer03_box">
                <figure>
                  <img
                    src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-r.png`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="footer02_links footer03_links">
                <ul className="footer02_contact_details_list">
                  <li>
                    <i className="fas fa-map-marker-alt" /> Địa chỉ
                    <div>
                      Thôn My Thượng - Xã Thanh Mai - Huyện Thanh Oai - Thành
                      phố Hà Nội
                    </div>
                  </li>
                  <li>
                    <i className="fas fa-phone" />
                    <a href="tel:+ (84)981416345">+ (84)981416345</a>
                  </li>
                  <li>
                    <i className="fas fa-envelope" />
                    <a href="mailto:trinhtien.2701@gmail.com">
                      trinhtien.2701@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 footer01_links_mobile">
              <div className="footer_01_box_inner">
                <div className="margin_bottom_20 footer03_heading">
                  Thông tin
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/">Trang chủ</a>
                  </li>
                  <li>
                    <a href="/contact">Liên hệ</a>
                  </li>
                  <li>
                    <a href="/agreement">Điều khoản</a>
                  </li>
                  <li>
                    <a href="/sla">SLA</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 footer01_links_mobile">
              <div className="footer_01_box_inner">
                <div className="margin_bottom_20 footer03_heading">
                  Sản phẩm
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/list-server-vn">VPS</a>
                  </li>
                  <li>
                    <a href="/list-dedicated-server-eu">Dedicated Server</a>
                  </li>
                  <li>
                    <a href="/list-proxy-vn">Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="d-md-none"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                className="footer_01_box_inner"
                style={{ marginRight: "64px" }}
              >
                <div className="margin_bottom_20 footer03_heading">
                  Thông tin
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/">Trang chủ</a>
                  </li>
                  <li>
                    <a href="/contact">Liên hệ</a>
                  </li>
                  <li>
                    <a href="/agreement">Điều khoản</a>
                  </li>
                  <li>
                    <a href="/sla">SLA</a>
                  </li>
                </ul>
              </div>
              <div className="footer_01_box_inner">
                <div className="margin_bottom_20 footer03_heading">
                  Sản phẩm
                </div>
                <ul className="footer03_links footer03_links_list">
                  <li>
                    <a href="/list-server-vn">VPS</a>
                  </li>
                  <li>
                    <a href="/list-dedicated-server-eu">Dedicated Server</a>
                  </li>
                  <li>
                    <a href="/list-proxy-vn">Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid bg_ecedf1 padding_top_10 padding_bottom_10 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 mobile_center">
              <div className="">© All rights reserved by VPSMMO247</div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12">
              <ul className="footer03_social_links">
                <li>
                  <a href="https://www.facebook.com/vpsmmo247">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
