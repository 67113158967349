/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";

export default function Header() {
  const navigate = useNavigate();

  return (
    <div id="default_header">
      <header>
        <nav className="navbar navbar-expand-lg top_navigation">
          <a className="navbar-brand default_logo" href="/">
            <figure>
              <img
                src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`}
                alt=""
              />
            </figure>
          </a>
          <ul className="ml-auto d-lg-none">
            <li>
              <a
                className="ml-2"
                style={{ alignSelf: "center" }}
                href={URL.Register}
              >
                {" "}
                Đăng ký
              </a>{" "}
              |<a href={URL.Login}> Đăng nhập</a>
            </li>
          </ul>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse mobile_navbar"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav navigation_bar">
              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.Home);
                  }}
                >
                  Trang chủ
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  VPS
                </a>
                <div
                  className="dropdown-menu sub_dropdown hosting_dropdown default_dd_menu header_from_top"
                  aria-labelledby="navbarDropdown"
                >
                  <ServerCategory />
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dedicated Server
                </a>
                <div
                  className="dropdown-menu sub_dropdown hosting_dropdown default_dd_menu header_from_top"
                  aria-labelledby="navbarDropdown"
                >
                  <DedicatedServerCategory />
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Proxy
                </a>
                <div
                  className="dropdown-menu sub_dropdown hosting_dropdown default_dd_menu header_from_top"
                  aria-labelledby="navbarDropdown"
                >
                  <ProxyCategory />
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  style={{ cursor: "pointer" }}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  S5 Proxy
                </a>
                <div
                  className="dropdown-menu sub_dropdown hosting_dropdown default_dd_menu header_from_top"
                  aria-labelledby="navbarDropdown"
                >
                  <S5ProxyCategory />
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.Guide);
                  }}
                >
                  Hướng dẫn
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.Agreement);
                  }}
                >
                  Điều khoản
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.SLA);
                  }}
                >
                  SLA
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(URL.Contact);
                  }}
                >
                  Liên hệ
                </a>
              </li>
            </ul>
            <ul className="ml-auto right_nav_links">
              <li>
                <div className="bg_social_icon float-left">
                  <i className="fa fa-lock" />
                </div>
                <a href={URL.Register}> Đăng ký</a>{" "}
                <a href={URL.Login}> Đăng nhập</a>
              </li>
            </ul>
          </div>
        </nav>
        {/*Ends Here*/}
      </header>
      {/*Ends Here*/}
    </div>
  );
}
