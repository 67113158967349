import React from "react";

interface IGuide {
  children: React.ReactNode;
}
export default function GuideRightContent(props: IGuide) {
  const { children } = props;
  return (
    <div className="hm-blog-sidebar mt-4 mt-xl-0">
      <div className="sidebar-widget categories-widget">
        <h4 className="widget-title mb-4">Bài viết liên quan</h4>
        <ul className="mt-30">
          {children}
        </ul>
      </div>
    </div>
  );
}
