/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

// import FeaturePlan from "components/FeaturePlan";
import { useAppDispatch } from "store";
import { actionGetListPlan } from "components/FeaturePlan/slice";
import { useEffect } from "react";
import FeaturePlan from "components/FeaturePlan";
import BuyProductBanner from "./BuyProductBanner";
import URL from "constant/url";
// import { nanoid } from "@reduxjs/toolkit";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Content() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true }));
  }, [dispatch]);
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="hero_banner_img padding_bottom_35  float-left w-100">
        <div className="banner_content">
          <div className="container">
            <div className="row mobile_banner_margin">
              <div className="col-xl-6 col-lg-6 col-md-6  padding_top_200 order02 mobile_text_center">
                <h1 className="font_size_48 line_height_60 color_222 margin_bottom_0">
                  Dịch vụ máy chủ ảo
                </h1>
                <h1 className="line_height_60 color_222 margin_bottom_0" style={{fontSize: '24px'}}>
                Chuyên nghiệp, đa dạng, giá hợp lí
                  <br />
                </h1>
                <p className="font_size_18 line_height_30 color_5a5f69 margin_bottom_30 font_weight_500">
                  Máy chủ giá chỉ từ 79.000 VNĐ/tháng
                </p>
                <a href={URL.Register} className="learn-more">
                  <div className="circle">
                    <span className="icon arrow" />
                  </div>
                  <p className="button-text">Trải nghiệm ngay</p>
                </a>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 order01 mobile_img">
                <figure>
                  <img
                    src="assets/images/hero_banner_img.svg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      {/*Hosting Plans Section*/}
      <section className="padding_top_60 padding_bottom_60 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
            Những Plan Tiêu Biểu
          </h2>
          <p className="margin_bottom_50" style={{ fontSize: "16px" }}>
            Cấu hình tối ưu hóa với giá cả hợp lý nhất.
          </p>
          <div className="row margin_bottom_20">
            <FeaturePlan />
          </div>
        </div>
      </section>
      {/*End Here*/}
      {/*Hosting Features Plans*/}
      <section className="padding_top_60 padding_bottom_35 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
            VPSMMO247 Best Services
          </h2>
          <p className="padding_bottom_100" style={{ fontSize: "16px" }}>
            Chúng tôi cung cấp nhiều dịch vụ khác nhau với giá thành hợp lý.
          </p>
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 welcome_section_img">
              <figure>
                <img
                  src="assets/images/welcome_section_img.svg"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col" />
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="row margin_bottom_40">
                <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                  <div className="company_box01 hover_effect">
                    <div className="company_detail_heading" style={{fontSize: '12px'}}>
                      DEDICATED SERVER
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                  <div className="company_box02 hover_effect">
                    <div className="company_detail_heading" style={{fontSize: '12px'}}>VPS SSD</div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-4">
                  <div className="company_box03 hover_effect">
                    <div className="company_detail_heading" style={{fontSize: '12px'}}>VPS FOR PTU</div>
                  </div>
                </div>
              </div>
              <p className="text-left margin_bottom_40 mobile_center">
                Mọi thông tin khách hàng được mã hóa. Hạn chế rủi ro hệ thống.
                Bảo đảm dữ liệu bảo mật và an toàn.
                Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử dụng trong phần Quản Trị.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
      {/* Guarantee Section*/}
      <section className="padding_bottom_45 padding_top_35 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222">
            Tại sao chọn chúng tôi?
          </h2>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 padding_top_170 margin_box">
              <div className="guarantee_box">
                <div className="guarantee_img01" />
                <div className="guarantee_box_heading margin_bottom_10">
                  Chính Sách Hoàn Trả
                </div>
                <p>
                  Nếu quý khách không hài lòng với dịch vụ, chúng tôi sẽ hỗ trợ
                  hoàn lại tiền không cần lý do.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="guarantee_box margin_box mobile_margin_box">
                <div className="guarantee_img02" />
                <div className="guarantee_box_heading margin_bottom_10">
                  SSD Chuyên Dụng
                </div>
                <p>
                  Sử dụng những phần cứng bền bỉ và ổ SSD để mang lại trải
                  nghiệm tốt nhất cho khách hàng.
                </p>
              </div>
              <div className="guarantee_box margin_box mobile_margin_box">
                <div className="guarantee_img03" />
                <div className="guarantee_box_heading margin_bottom_10">
                  VPS Đa Dạng
                </div>
                <p>
                  Đa dạng quốc gia, giá cả phù hợp với cấu hình, hỗ trợ cài đặt
                  Windows và Linux Free.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 padding_top_170">
              <div className="guarantee_box">
                <div className="guarantee_img04" />
                <div className="guarantee_box_heading margin_bottom_10">
                  VPS Đa Dạng
                </div>
                <p>
                  Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng cho
                  mọi tình huống, hỗ trợ nhanh chóng.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
      <BuyProductBanner />
    </>
  );
}
