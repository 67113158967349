/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Contact() {
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="hero_banner_img padding_top_60 padding_bottom_60 pb-0">
        <div className="banner_content">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 padding_top_100 margin_box sub_page_banner_content_box order02 mobile_text_center">
                <h1 className="font_size_48 line_height_60 color_222 margin_bottom_20">
                  Hãy liên hệ với chúng tôi
                </h1>
                <p className="font_size_18 line_height_30 color_5a5f69 margin_bottom_20 inner_sub_page_banner_content_box">
                  Thời gian là tiền bạc! Chúng tôi xem trọng khách hàng và thời
                  gian của mọi người, vì vậy, đội ngũ của chúng tôi luôn làm tốt
                  nhất có thể để giúp bạn giải quyết vấn đề nhanh chóng!
                </p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 sub_page_banner_img order01">
                <figure>
                  <img
                    src="assets/images/sub_banner_img06.svg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      {/*Help Section*/}
      <section className="padding_bottom_60 padding_top_60 text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5 help_section_img">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3728.066033908052!2d105.74363117583833!3d20.86938849323229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31344d69d7e84a9b%3A0x8b1558fe788af878!2zTmjDoCBWxINuIEjDs2EgVGjDtG4gTXkgVGjGsOG7o25n!5e0!3m2!1svi!2s!4v1699639992999!5m2!1svi!2s"
                width="100%"
                height="100%"
                loading="lazy"
              ></iframe>
            </div>
            <div className="col" />
            <div className="col-xl-6 col-lg-6 col-md-6 text-left mobile_text_center">
              <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222 ">
                Thông Tin Liên Hệ
              </h2>
              <p>Facebook</p>
              <a
                href="https://www.facebook.com/vpsmmo247"
                className="text-decoration-none"
              >
                <p className="pp">https://www.facebook.com/vpsmmo247</p>
              </a>
              <p>Skype</p>
              <a href="" className="text-decoration-none">
                <p className="pp">trinhtien.2701</p>
              </a>
              <p className="callnow">Telegram</p>
              <a href="https://t.me/trinhtien2701">@trinhtien2701</a>
              <p>ICQ: 712621960</p>
            </div>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
    </>
  );
}
