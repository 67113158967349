import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
    <>
      <section className="bg_sub_banner text-center affiliates_banner">
        <div className="banner_inner_content padding_top_50">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 padding_top_100 margin_box sub_page_banner_content_box order02 mobile_text_center text-left">
                <h1 className="font_size_48 line_height_60 color_222 margin_bottom_20">
                  Hướng dẫn sử dụng
                </h1>
                <p className="font_size_18 line_height_30 color_5a5f69 margin_bottom_20 inner_sub_page_banner_content_box">
                  Chia sẻ và tổng hợp những hướng dẫn kĩ thuật, tips sử dụng về
                  VPS cũng như mọi thứ liên quan.
                </p>
                <p className="inner_sub_page_banner_content_box01">
                  Hi vọng với những thông tin mà chúng tôi cung cấp sẽ giúp quý
                  khách hàng giải quyết thắc mắc một cách nhanh chóng và chính
                  xác.
                </p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 sub_page_banner_img order01">
                <figure>
                  <img
                    src="assets/images/sub_banner_img06.svg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding_top_80 latest_news_section">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 float-left">
              <div
                className="col-lg-12 col-md-12 col-sm-12 news_box first_news_box"
                data-aos="fade-up"
              >
                <figure className="margin_bottom_50">
                  <img
                    src="assets/images/blog_img_01.jpg"
                    alt="sms_counter"
                    className="img-fluid"
                  />
                </figure>
                <div className="latest_news">
                  <h2 className="margin_bottom_30 color_333">
                    Hướng dẫn tăng cường bảo mật VPS
                  </h2>
                  <p className="padding_bottom_20">
                    Bạn đang chưa biết có cách nào để tăng cường bảo mật VPS.
                    Những cách bảo mật VPS an toàn và hiệu quả nhất hiện nay đó
                    là: Thay đổi port mặc định, dùng phiên bản phần mềm mới,...
                  </p>
                  <a href={URL.Guide1} className="learn-more margin_bottom_50">
                    <div className="circle">
                      <span className="icon arrow" />
                    </div>
                    <p className="button-text">CHI TIẾT</p>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-12 col-md-12 col-sm-12 news_box first_news_box"
                data-aos="fade-up"
              >
                <figure className="margin_bottom_50">
                  <img
                    src="assets/images/blog_img_02.jpg"
                    alt="sms_counter"
                    className="img-fluid"
                  />
                </figure>
                <div className="latest_news">
                  <h2 className="margin_bottom_30 color_333">
                    Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                  </h2>
                  <p className="padding_bottom_20 nunito_font">
                    Có 2 cách thông dụng để đổi mật khẩu VPS WS2012 là bằng dòng
                    lệnh hoặc cài đặt trong User Accounts. Cách 1: Mở CMD hoặc
                    Powershell và chạy dòng lệnh Net user administrator
                    Mậtkhẩubạnmuốnđổi. Lưu ý: Mật khẩu phải bao gồm chữ IN HOA
                    và chữ thường (nếu thêm ký tự đặc biệt càng tốt)...
                  </p>
                  <a href={URL.Guide2} className="learn-more margin_bottom_50">
                    <div className="circle">
                      <span className="icon arrow" />
                    </div>
                    <p className="button-text">CHI TIẾT</p>
                  </a>
                </div>
              </div>
              <div
                className="col-lg-12 col-md-12 col-sm-12 third_blog_post_box news_box first_news_box"
                data-aos="fade-up"
              >
                <figure className="margin_bottom_50">
                  <img
                    src="assets/images/blog_img_03.jpg"
                    alt="sms_counter"
                    className="img-fluid"
                  />
                </figure>
                <div className=" latest_news">
                  <h2 className="margin_bottom_30 color_333">
                    Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
                  </h2>
                  <p className="padding_bottom_20 nunito_font">
                    Để mở rộng ổ cứng có thể sử dụng powershell hoặc công cụ
                    Disk Management. Cách 1: Dùng powershell. Bước 1 : Mở
                    Powershell : 1. Nhấn các phím Win+S để mở Search. 2. Gõ
                    powershell vào hộp tìm kiếm, nhấp chuột phải trong kết quả
                    tìm kiếm và chọn Run as administrator...
                  </p>
                  <a href={URL.Guide3} className="learn-more">
                    <div className="circle">
                      <span className="icon arrow" />
                    </div>
                    <p className="button-text">CHI TIẾT</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
