/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function SLA() {
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="hero_banner_img padding_top_60 padding_bottom_60">
        <div className="banner_content">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 padding_top_100 margin_box sub_page_banner_content_box order02 mobile_text_center">
                <h1 className="font_size_48 line_height_60 color_222 margin_bottom_20">
                  Chất Lượng Dịch Vụ Tốt Nhất
                </h1>
                <p className="font_size_18 line_height_30 color_5a5f69 margin_bottom_20 inner_sub_page_banner_content_box">
                  Chúng tôi đảm bảo dịch vụ của Quý khách đang sử dụng đạt
                  uptime từ 99,99% trở lên.
                </p>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 sub_page_banner_img order01">
                <figure>
                  <img
                    src="assets/images/sub_banner_img06.svg"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      {/*About Hosting Section*/}
      <section className="padding_top_60 padding_bottom_60 text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-gl-6 col-md-12 margin_box">
              <div className="about_hosting_box mobile_margin_bottom">
                <div className="company_box01 hover_effect about_hosting_box_img">
                  <div className="company_detail_heading">
                    100% Host Node Uptime
                  </div>
                </div>
                <p>
                  Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn được
                  thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm kỹ sư
                  của chúng tôi sử dụng giám sát tích cực để chủ động phát hiện
                  các vấn đề và thực hiện các biện pháp phòng ngừa, giảm thiểu
                  bất kỳ tác động nào khi phần cứng máy chủ bị lỗi có thể gây ra
                  đối với môi trường của bạn.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-gl-6 col-md-12 margin_box">
              <div className="about_hosting_box mobile_margin_bottom">
                <div className="company_box01 hover_effect about_hosting_box_img">
                  <div className="company_detail_heading">
                    100% Network Uptime
                  </div>
                </div>
                <p>
                  Sử dụng kiến trúc dự phòng và định tuyến BGP thông minh, mạng
                  lõi định tuyến một cách thông minh xung quanh hoạt động mạng
                  gián đoạn nhằm nỗ lực cung cấp 100% thời gian hoạt động và kết
                  nối hiệu suất cao trong tất cả các POP trên toàn thế giới của
                  chúng tôi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding_top_60 padding_bottom_60 text-center">
        <div className="container">
          <h2 className="margin_bottom_20 font_size_36 line_height_48 color_222 sailec_medium">
            Cam kết đền bù phí dịch vụ
          </h2>
          <p className="margin_bottom_50">
            Trường hợp tỷ lệ hoạt động hàng tháng của máy chủ ảo giảm xuống dưới
            99,9%, chúng tôi sẽ khấu trừ số tiền được tính theo tỷ lệ hiển thị
            bên dưới cho chi phí sử dụng trong tháng xảy ra sự cố.
          </p>
          <div className="row">
            <div className="col-xl-4 col-lg-4 domain_tlds_img">
              <figure>
                <img
                  src="assets/images/domain_tlds_img.svg"
                  alt=""
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col-xl-8 col-lg-8">
              <article>
                <table className="table_domains">
                  <thead>
                    <tr>
                      <th className="">Thời gian uptime hàng tháng</th>
                      <th className="">
                        Thời gian ở trạng thái không sẵn sàng{" "}
                      </th>
                      <th className="">Mức phí dịch vụ đền bù</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="">Từ 99,9% tới 100%</td>
                      <td className="">Tối đa 43 phút</td>
                      <td className="">0%</td>
                    </tr>
                    <tr>
                      <td className="">Từ 99% tới ít hơn 99,9%</td>
                      <td className="">Tối đa 430 phút</td>
                      <td className="">30%</td>
                    </tr>
                    <tr>
                      <td className="">Từ 90% tới ít hơn 99%</td>
                      <td className="">Tối đa 4300 phút</td>
                      <td className="">50%</td>
                    </tr>
                    <tr>
                      <td className="">Ít hơn 90%</td>
                      <td className="">Hơn 4300 phút</td>
                      <td className="">100%</td>
                    </tr>
                  </tbody>
                </table>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
