import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { get } from "lodash";
import request from "utils/request";

type Status = "idle" | "loading" | "success" | "error";

export interface PayloadActionListPlanDetail {
  id: number;
  slug: string;
  name: string;
  description: string;
  price: number;
  cpu: string;
  ram: string;
  ssd: string;
  bandwidth: string;
  ethernet_port: string;
  ipv4: string;
  status: string;
  featured: boolean;
  region: string;
}

interface paramsGetListFeature {
  featured: boolean;
}

interface GetListPlanState {
  listPlanDetail: PayloadActionListPlanDetail[];
  statusLoadListPlanDetail: Status;
}

const initialState: GetListPlanState = {
  listPlanDetail: [],
  statusLoadListPlanDetail: "idle",
};

export const actionGetListPlan = createAsyncThunk(
  "listPlan/actionGetListPlan",
  async (params: paramsGetListFeature, { rejectWithValue }) => {
    try {
      const response = await request({
        url: "api/plan/vps",
        method: "GET",
        params,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: "featurePlan",
  initialState,
  reducers: {
    actionResetStatus(state) {
      state.statusLoadListPlanDetail = "idle";
    },
    actionResetStore() {
      return { ...initialState };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(actionGetListPlan.pending, (state) => {
        state.statusLoadListPlanDetail = "loading";
      })
      .addCase(actionGetListPlan.fulfilled, (state, action) => {
        state.statusLoadListPlanDetail = "success";
        state.listPlanDetail = get(action, "payload.data").map(
          (item: PayloadActionListPlanDetail) => ({ ...item })
        );
      })
      .addCase(actionGetListPlan.rejected, (state) => {
        state.statusLoadListPlanDetail = "error";
      });
  },
});

export const { actionResetStatus, actionResetStore } = slice.actions;

export const selectListPlanDetail = (state: RootState) =>
  state.featurePlan.listPlanDetail;
export const selectLoadingListPlanDetail = (state: RootState) =>
  state.featurePlan.statusLoadListPlanDetail;

export default slice.reducer;
