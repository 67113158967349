/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { actionGetListPlan, selectListPlanDetail } from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import { nanoid } from "@reduxjs/toolkit";
import { MANAGEMENT_URL } from "constant/app";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true }));
  }, [dispatch]);

  return (
    <div className="row justify-content-center">
      {listPlanDetail.map((item) => (
        <div className="col-xl-4 col-lg-4 col-md-4 margin_box" key={nanoid()}>
          <div className="hosting_plan_box">
            <div className="hosting_plan_name">VPS {item.region}</div>
            <div
              className="hosting_plan_box_price"
              style={{ fontSize: "32px" }}
            >
              {item.price}
              <span>VNĐ/Tháng</span>{" "}
            </div>
            <ul className="hosting_plan_listing">
              <li>CPU: {item.cpu}</li>
              <li>RAM: {item.ram}</li>
              <li>SSD: {item.ssd}</li>
              <li>IPv4: {item.ipv4}</li>
              <li>Bandwidth: {item.bandwidth}</li>
              <li>Ethernet port: {item.ethernet_port}</li>
            </ul>
            <a
              href={`${MANAGEMENT_URL}/buying?type=VPS&nation=${item.region}`}
              className="learn-more"
            >
              <div className="circle">
                <span className="icon arrow" />
              </div>
              <p className="button-text">Mua ngay</p>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
